const Cookie = require('js-cookie');
const scripts = require('~scripts');

const appendScript = (obj) => {
	const script = document.createElement("script");
	script.src = obj.src;
	script.async = true;

	switch (obj.location) {
		case "head":
			document.head.appendChild(script);
			break;
		case "body":
			document.body.appendChild(script);
			break;
		default:
			break;
	}

	return "";
};

const initGDPRScripts = () => {
	const cookies = Cookie.get('gdpr') ? JSON.parse(Cookie.get('gdpr')) : null;
	if(!cookies) {
		return;
	}


	Object.entries(cookies).forEach((entry) => {
		const currentScripts = scripts[entry[0]];
		if (!entry[1] || !currentScripts) {
			return;
		}

		currentScripts.forEach((currentScript) => {
			appendScript(currentScript);
		});
	});
};


exports.onInitialClientRender = () => {
	initGDPRScripts()
};