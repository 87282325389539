module.exports = {
  cookiesNecessary: [
    {
      // src: "/uploads/scripts/sample-one.js",
      // location: "head",
    },
  ],
  cookiesStatistics: [
    {
      // src: "/uploads/scripts/sample-two.js",
      // location: "head",
    },
  ],
};